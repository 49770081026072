import * as Yup from 'yup';

const FULL_NAME_REGEX = /^([a-zA-Z]{2,}\s[a-zA-Z]{1,}'?-?[a-zA-Z]{2,}\s?([a-zA-Z]{1,})?)/;

export const interestFormSchema = Yup.object().shape({
  companyName: Yup
    .string()
    .min(2, 'Company name too short!')
    .max(50, 'Company name too Long!')
    .required('Company name is required'),

  email: Yup
    .string()
    .email('Email address is invalid')
    .required('Email address is required'),

  fullName: Yup
    .string()
    .trim()
    .matches(FULL_NAME_REGEX, 'Full name is invalid')
    .required('Full name is required'),

  phoneNumber: Yup
    .string()
    .min(10, 'Phone number is invalid')
    .max(12, 'Phone number is invalid')
    .required('Phone number is required'),
});
