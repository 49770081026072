import { addHttpsIfNone } from '@bluefinenterprises/bluefin-libs';

/**
 * Re-Encodes a URI component so that encoding is employed, however the semicolon character which is sometimes considered on-spec is preserved
 *  and sending the encoded format may break some websites.
 * @param uri_component the uri component to encode
 */
export const safeEncodeURIComponent = (uri_component: string) => encodeURIComponent(uri_component).replace('%3B', ';');

export const getRedirectUrl = (productId: string, source: string): string => `/redirect/?product=${productId}&url=${safeEncodeURIComponent(
  addHttpsIfNone(source),
)}`;

export const redirectUrl = (productId: string, source: string): void => {
  window.open(getRedirectUrl(productId, window?.location?.href || source), '_blank').focus();
};

export const getSourceFromQuery = (query: Record<string, string>) => query.gclid
  || query.msclkid
  || query.fbclid
  || query.ttclid
  || query.tid
  || query.gbraid
  || query.wbraid
  || query.cid
  || query.rdt_cid
  || query.qclid
  || query.yclid;
